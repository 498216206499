.text-right { text-align: right; }
.status-completed,
.status-approved{
	color: green !important;
}
.status-pending{
	color: pink !important;
}
.status-cancelled{
	color: red !important;
}
.flex-row{
	display: flex;
	direction: row;
}